import Sedan from '../assets/icons/sedan.svg';
import Truck from '../assets/icons/camionetas.svg';
import SUV from '../assets/icons/suv.svg';
import Premium from '../assets/icons/gama_alta.svg';
import Van from '../assets/icons/van.svg';

export interface IVehicle {
    image: any;
    model: string;
    brand: string;
    features: string[];
    price: number
}

interface IVehicleCategory {
    name: string;
    icon: any;
    vehicles: IVehicle[];
}

const VEHICLES: IVehicleCategory[] = [
    {
        name: 'Sedanes',
        icon: Sedan,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_aveo.png'),
                model: 'Aveo',
                price: 55,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sail.png'),
                model: 'Sail',
                price: 65,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Hyundai',
                image: require('../assets/vehicles/hyundai_elantra.png'),
                model: 'Elantra',
                price: 70,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_prius.png'),
                model: 'Priux',
                price: 80,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            }
        ]
    },
    {
        name: 'Camionetas',
        icon: Truck,
        vehicles: [
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_dmax.png'),
                model: 'Dmax',
                price: 85,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            },
            {
                brand: 'Mazda',
                image: require('../assets/vehicles/mazda_bt50.png'),
                model: 'Bt50',
                price: 85,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth'
                ]
            }
        ]
    },
    {
        name: 'SUV',
        icon: SUV,
        vehicles: [
            {
                brand: 'Kia',
                image: require('../assets/vehicles/kia_sportage.png'),
                model: 'Sportage',
                price: 130,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_scross.png'),
                model: 'Scross',
                price: 110,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_sz.png'),
                model: 'Sz',
                price: 120,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            }
        ]
    },
    {
        name: 'Gama Alta',
        icon: Premium,
        vehicles: [
            {
                brand: 'Nissan',
                image: require('../assets/vehicles/nissan_xtrail.png'),
                model: 'Xtrail',
                price: 160,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Toyota',
                image: require('../assets/vehicles/toyota_fortuner.png'),
                model: 'Fortuner',
                price: 180,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
            {
                brand: 'Chevrolet',
                image: require('../assets/vehicles/chevrolet_tahoe.png'),
                model: 'Tahoe',
                price: 190,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x4'
                ]
            },
        ]
    },
    {
        name: 'Van',
        icon: Van,
        vehicles: [
            {
                brand: 'Hyundai',
                image: require('../assets/vehicles/hyundai_h1.png'),
                model: 'H1',
                price: 210,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            },
            {
                brand: 'Cinascar',
                image: require('../assets/vehicles/cinascar_cherry.png'),
                model: 'Cherry',
                price: 140,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            },
            {
                brand: 'Volskwagen',
                image: require('../assets/vehicles/volskwagen_crafter.png'),
                model: 'Crafter',
                price: 170,
                features: [
                    'Aire Acondicionado',
                    '5 Asientos',
                    'Bluetooth',
                    'Tracción 4x2'
                ]
            }
        ]
    }
]

export default VEHICLES;
