import React from "react";
import ContactFormComponent from "../global/ContactFormComponent";

interface IContactProps {
    contactOpen: boolean
    changeContactOpen: (isOpen: boolean) => void
}

const ModalContactComponent = (props: IContactProps) => {
    const close = () => {
        props.changeContactOpen(false)
    }

    return <div className="contact-modal">
        {props.contactOpen &&
            <div className="modal-content">
                <div className="close-bg" onClick={close} />
                <div className="bg-white form-area radius-big flex-row">
                    <div className="block-area flex-column flex-075 flex-center relative">
                        <a className="close-contact size-2" onClick={close}><i className="icon-close" /></a>
                        <iframe
                            frameBorder="0"
                            scrolling="no"
                            src="https://maps.google.com/maps?q=Wm%20Rentacar&amp;t=m&amp;z=19&amp;output=embed&amp;iwloc=near"
                            aria-label="Wm Rentacar"
                            style={{width: '100%', height: '100%'}}
                        />
                    </div>
                    <div className="block-area flex-column bg-gray p-all flex-1 form-content">
                        <p className="strong text-white line-height-small m-t">
                            Wm Rent a Car - Alquiler Autos Quito Ecuador - Ecuador
                        </p>
                        <p className="text-white p-t p-b line-height-small">
                            Edificio Genesis - Oficina2 Gonzalo Serrano E10-62, Y, Quito 170504
                        </p>
                        <ContactFormComponent />
                    </div>
                </div>
            </div>
        }
    </div>
}

export default ModalContactComponent;
