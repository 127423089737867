import email from '../../assets/icons/mail.svg';
import facebook from '../../assets/icons/facebook.svg';
import React from "react";

const Header = (props: {changeContactOpen: (open: boolean) => void}) => {
    return <header className="header flex-row flex-middle size-small text-gray text-lighten-2">
        <div className="header-left p-l p-r flex-column flex-1">
            <div className="flex-row flex-middle">
                <a href="" className="flex-row p-r-s border-r border-gray border-lighten-4">
                    <img src={facebook} className="App-logo" alt="logo" />
                </a>
                <a onClick={() => props.changeContactOpen(true)} className="flex-row p-l-s">
                    <img src={email} className="App-logo" alt="logo" />
                    <span className="p-l-s strong contact-text">
                        <span>CONTACTA A WM RENT A CAR MANTA</span>
                        <span className="text-gray text-lighten-1 light address-contact">
                            info@alquilerdeautos.ec | Manta | 0995120920
                        </span>
                    </span>
                </a>
            </div>
        </div>
        <div className="header-right p-l p-r flex-column">
            <span className="text-gray text-lighten-1 light">info@alquilerdeautos.ec | Manta - Ecuador | 0995120920 | Copyright 2021</span>
        </div>
    </header>
}

export default Header
