import ws from '../../assets/icons/ws.svg'
import RentFormComponent from "../global/RentFormComponent";
import React from "react";

const AsideForm = (props: {
    responsiveOpen: boolean,
    openWA: (vehicle: string) => void
    handleResponsiveOpen: (isOpen: boolean) => void
}) => {
    return <div className={`aside-form ${props.responsiveOpen && 'responsive-open'}`}>
        <div className="aside-content p-lg-all">
            <div className="text-white">
                <a className="close-button" onClick={() => props.handleResponsiveOpen(false)}><i className="icon-close" /></a>
                <h1 className="light line-height-small p-t p-b">
                    <span className="line-height-small block size-4">Alquila tu vehículo </span>
                    <span className="block size-2 line-height-small">En Manta ahora!</span>
                </h1>
                <p className="light p-t p-b">
                    Estás a un paso de rentar el vehículo que más se adapta a tus necesidades; completa el siguiente formulario para generar una pre-reserva
                </p>
                <RentFormComponent />
            </div>
        </div>
        <div className="aside-cta">
            <a className="aside-footer-content width-100 flex-row flex-middle p-l p-r" onClick={() => props.openWA('')}>
                <div className="flex-column p-l p-r">
                    <img src={ws} alt=""/>
                </div>
                <div className="flex-column p-r flex-1 text-white line-height-small">
                    <span className="strong size-4">0995120920</span>
                    <span>Línea WhatsApp para alquilar tu vehículo</span>
                    <span className="strong size-5">Click aquí</span>
                </div>
            </a>
        </div>
    </div>
}

export default AsideForm
