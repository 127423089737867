import React from "react";
import RentFormComponent from "../global/RentFormComponent";
import {IVehicle} from "../../data/vehicles";

interface IDateRangeProps {
    vehicleOpen: IVehicle
    changeVehicleOpen: (vehicleOpen?: IVehicle | null) => void
}

const ModalRentComponent = (props: IDateRangeProps) => {
    const closeRange = () => {
        props.changeVehicleOpen(null)
    }

    console.log(props)

    return <div className="rent-modal">
        {props.vehicleOpen &&
            <div className="modal-content">
                <div className="close-bg" onClick={closeRange} />
                <div className="bg-white form-area radius-big flex-row">
                    <div className="block-area flex-column p-all flex-075 flex-center relative">
                        <a className="close-rent size-2" onClick={() => props.changeVehicleOpen(null)}><i className="icon-close" /></a>
                        <div className="flex-row flex-center">
                            <img src={props?.vehicleOpen?.image?.default} alt=""/>
                        </div>
                        <div>
                            <h3 className="p-t p-b border-b border-gray border-lighten-5 line-height-small features-title">
                                <span className="text-gray text-lighten-3">Alquilar: </span> {props.vehicleOpen.brand} {props.vehicleOpen.model}
                            </h3>
                            <div className="flex-row p-t p-b flex-no-wrap features">
                                <div className="flex-column text-gray text-lighten-4 line-height-small p-t-s">
                                    <small className="text-uppercase">desde</small>
                                    <span className="size-3 text-gray text-lighten-1 strong line-height-small">${props.vehicleOpen.price}</span>
                                    <small>diarios</small>
                                </div>
                                <ul className="flex-column m-t-none feature-list">
                                    {props.vehicleOpen.features.map((ft, sk) => {
                                        return <li key={sk}>
                                            {ft}
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="block-area flex-column bg-gray p-all flex-1 form-content">
                        <p className="text-white p-t p-b">
                            Completa el siguiente formulario para tu premio reserva y uno de nuestros asesores te contactará
                        </p>
                        <RentFormComponent vehicleActive={true} />
                    </div>
                </div>
            </div>
        }
    </div>
}

export default ModalRentComponent;
