import React from 'react';
import Header from "./components/layout/Header";
import AsideForm from "./components/layout/AsideForm";
import Home from "./components/sections/Home";
import VehiclesCatalog from "./components/sections/VehiclesCatalog";
import ModalRentComponent from "./components/layout/ModalRentComponent";
import ModalContactComponent from "./components/layout/ModalContactComponent";

function App() {
  const [responsiveOpen, handleResponsiveOpen] = React.useState(false);
  const [vehicleOpen, changeVehicleOpen] = React.useState<any>(null);
  const [contactOpen, changeContactOpen] = React.useState<boolean>(false);
  const openWA = (vehicle?: string) => {
      const phone = '+593995120920'
      if (!vehicle) {
          window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Estoy%20interesado%20en%20alquilar%20un%20auto`, '_blank')
      } else {
          window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Estoy%20interesado%20en%20alquilar%20el%20auto%20${vehicle}`, '_blank')
      }
      console.log('vehicle', vehicle)
  }
  return (
    <div className="App">
        <AsideForm handleResponsiveOpen={handleResponsiveOpen} responsiveOpen={responsiveOpen} openWA={openWA}/>
        <Header changeContactOpen={changeContactOpen}/>
        <Home />
        <VehiclesCatalog handleResponsiveOpen={handleResponsiveOpen} openWA={openWA} changeVehicleOpen={changeVehicleOpen}/>
        <ModalRentComponent vehicleOpen={vehicleOpen} changeVehicleOpen={changeVehicleOpen} />
        <ModalContactComponent changeContactOpen={changeContactOpen} contactOpen={contactOpen} />
    </div>
  );
}

export default App;
